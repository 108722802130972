import * as React from "react";
import Layout from "../components/layout";
import ScholarshipsContent from "../components/enrolment/scholarshipsContent";
import heroImage from "../assets/enrolment/enrolment-hero-image.jpg";
import BannerImage from "../components/master/BannerImage";
const ScholarshipsPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />
      <ScholarshipsContent />
    </Layout>
  );
};

export default ScholarshipsPage;
