import React from "react";
import enrolmentBottomImage from "../../assets/enrolment/bottomEnrolment.jpg";
import scholarshipApplicationForm from "../../assets/enrolment/StM_Application_form_scholarship.pdf";
import SideBar from "../master/SideBar";
import ContentWrapper from "../master/ContentWrapper";
import { EnrolmentSidebarLink } from "../const";
import PageTitle from "../master/PageTittle";

const ScholarshipContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Scholarship" />

        <p>The College offers two Scholarships:</p>
        <ul>
          <li>Academic Scholarship</li>
          <li>Rounder Scholarship</li>
        </ul>

        <PageTitle title="Selection criteria Academic:" variant="h3" />

        <p>
          Applicants are assessed using a combination of the following selection
          criteria:
        </p>
        <ul>
          <li>Academic records</li>
        </ul>

        <PageTitle title="Selection criteria All-rounder:" variant="h3" />
        <ul>
          <li>Sporting achievements</li>
          <li>Creative Arts achievement (music, performance or visual arts)</li>
          <li>Leadership and Community service</li>
          <li>Potential for contribution to Saint Maroun’s College</li>
        </ul>

        <PageTitle title="Eligibility:" variant="h3" />

        <p>
          To be eligible for the Year 7 to Year 11 Saint Maroun’s College
          Scholarships applicants must be:
        </p>
        <ul>
          <li>A new student entering Year 7 to 11 in 2021</li>
          <li>An Australian citizen or permanent resident</li>
        </ul>

        <PageTitle title="How to apply:" variant="h3" />

        <p>
          An application form must be completed and sent to{" "}
          <a href="mail:enrolments@stmarouns.nsw.edu.au">
            enrolments@stmarouns.nsw.edu.au
          </a>
        </p>

        <p>The following must also be provided:</p>
        <ul>
          <li>Recent photograph of the applicant</li>
          <li>Most recent reports</li>
          <li>Applicant’s most recent NAPLAN results</li>
          <li>
            Evidence of achievement in one of the following areas: academic,
            sport, creative arts, community service
          </li>
        </ul>
        <a
          href={scholarshipApplicationForm}
          type="button"
          className="btn enrolmentFormButton"
        >
          Scholarship Application Form
        </a>
      </div>

      <SideBar items={EnrolmentSidebarLink} title="Enrolments" />
    </ContentWrapper>

    <img
      src={enrolmentBottomImage}
      className="img-fluid justify-content-center "
      height={600}
      alt="..."
    />
  </div>
);

export default ScholarshipContent;
